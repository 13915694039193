.app__subheader {
  box-sizing: border-box;
  padding: 18px 0 10px;
  font-family: $main_font;
  text-align: center;
  height: 92px;
  letter-spacing: -0.1px;
  font-size: 12px;
  color: $text-black;
  width: 100%;
  background-color: $brand_color_main-faded;
}

.app__subheader__email__buttons {
  display: flex;
  box-sizing: border-box;
  padding: 18px 50px 10px;
  font-family: $main_font;
  text-align: center;
  height: 74px;
  letter-spacing: -0.1px;
  font-size: 12px;
  color: $text-white;
  width: 100%;
  background-color: $brand_color_secondary;
}

.app__subheader__email__schedule {
  box-sizing: border-box;
  padding: 18px 0 10px;
  font-family: $main_font;
  text-align: center;
  height: 74px;
  letter-spacing: -0.1px;
  font-size: 12px;
  color: $text-white;
  width: 100%;
  background-color: $navigation_color;
  position: relative;
    .app__small-input {
    margin-right: 10px;
    width: 200px;
    height: 34px;
    &:nth-child(2) {
      margin-right: 40px;
    }
  }
}

.app__subheader__list {
  list-style: none;
  padding-inline-start: 0;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  background-image: url(/img/steps-line.png);
  background-size: calc(100% - 100px) 100%;
  background-position: 50px -12px;
  background-repeat: no-repeat;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.app__subheader__crumb {
  display: inline-block;
  &:last-of-type {
    margin-right: 0;
  }
  padding-top: 8px;
  margin-top: -8px;
}

.app__subheader__crumb--selected {
  color: $brand_color_accent;
  font-weight: 600;
  background-image: url(/img/steps-bg.png);
  background-position: 50% -1px;
  background-repeat: no-repeat;
}

.app__top-line {
  width: 100%;
  height: 1px;
  background-color: $table-border;
  margin: 0;
}
.app__icon {
  width: 81px;
  height: 81px;
  border-radius: 50%;
  border: 2px solid $icon_border;
  background-image: url(/img/user.png);
  background-size: cover;
  cursor: pointer;
}

.app__bignumber {
  color: $text_white;
  box-sizing: border-box;
  font-size: 16px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: $brand_color_main;
  display: inline-block;
  font-weight: bold;
  padding-top: 5px;
  margin: 0 0 6px 0;
  text-align: center;
  background-size: 35px 35px;
  background-position: 52% 50%;
  background-repeat: no-repeat;
}

.app__subheader__crumb:nth-of-type(1) .app__bignumber {
  background-image: url(/img/icon--users.png);
}
.app__subheader__crumb:nth-of-type(2) .app__bignumber {
  background-image: url(/img/icon--camera.png);
}
.app__subheader__crumb:nth-of-type(3) .app__bignumber {
  background-image: url(/img/icon--mail.png);
}
.app__subheader__crumb:nth-of-type(4) .app__bignumber {
  background-image: url(/img/icon--check.png);
}

.app__subheader__crumb--selected .app__bignumber {
  background-color: $brand_color_accent;
  color: $text_white;
}

.app__subheader__crumb-text {
  font-family: $heading_font;
  color: $brand_color_secondary;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  margin-top: 2px;
}

.app__subheader__crumb--selected .app__subheader__crumb-text {
  color: $brand_color_accent;
  }

.skin2 {
  .app__bignumber {
    background-color: $secondary_form_color_2;
  }
  .app__subheader__crumb--selected .app__bignumber {
    background-color: $form_element_color_2;
  }
  .app__subheader__crumb--selected {
    color: $form_element_color_2;
  }
}

.skin3 {
  .app__bignumber {
    background-color: $secondary_form_color_3;
  }
  .app__subheader__crumb--selected .app__bignumber {
    background-color: $form_element_color_3;
  }
  .app__subheader__crumb--selected {
    color: $form_element_color_3;
  }
}
