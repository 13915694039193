.app__profile {
    button {
        cursor: pointer;
    }

    height: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 4px;
    font-family: $main_font;
    background-image: url(/img/axon_bg-stripes.png);
    background-size: 10px 175px;
    background-position: 0 0;
    background-repeat: repeat-x;
    background-color: $brand_color_secondary;
}

.app__profile__left {
    width: 48%;
    margin: 46px 0 0 50px;
    text-align: center;


    background-size: 10px 140px;
    background-repeat: repeat-x;
    border-radius: 10px;
    color: $text_white;
    height: 445px;
}

.app__profile__right {
    width: 38%;
    text-align: center;
    margin-left: 20px;
    margin-top: 46px
}

.app__profile__name {
    font-family: $heading_font;
    font-size: 28px;
    margin-top: 18px;
}

.app__profile__title {
    font-family: $heading_font;
    font-size: 18px;
    margin-bottom: 18px;
    margin-top: 5px;
}

.app__profile__top-area {
    padding: 48px 51px 0;
    font-family: $main-font;
    position: relative;
}

.app__profile__bottom-area {
    box-sizing: border-box;
    background: $brand_color_secondary;
    padding: 0 23px 0;
    font-family: $main-font;
    position: relative;
    width: 100%;
}

.app__profile__big-icon {
    width: 220px;
    height: 220px;
    border: 8px solid $text_white;
    background-image: url(/img/user-icon.png);
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 50%;
    margin: 0 auto 10px;
}

.app__profile__inputlist {
    margin: 25px auto 0;
    background-color: $brand_color_secondary;
    padding: 15px 0 25px;
    border: 2px solid white;
    margin-top: 15px;
    position: relative;
    z-index: 500;
}

.app__profile .app__simple-crumb {
 top: -3px;
}

.app__profile__input-text__label {
    display: block;
    margin: 0 12px 2px 0;
    clear: both;
}

.app__profile__input-text__label-text {
    margin: 0 0 4px 10%;
    padding: 0;
    color: #ddd;
    /*$admin_form_label;*/
    text-align: left;
    font-size: 14px;
}

.app__profile__input-text__label-text[disabled] {
    background-color: transparent !important;
}

.app__profile__input-text {
    float: left;
    height: 32px;
    width: 80%;
    box-sizing: border-box;
    border: 1px solid $med_grey;
    padding-left: 29px;
    font-size: 12px;
}

.app__profile__edit-button {
    border: none;
    box-shadow: none;
    background-color: $text_white;
    color: $brand_color_accent;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    padding: 6px 11px;
    margin: 30px 0 20px;
}

.app__profile__button {
    height: 42px;
    width: 260px;
    box-sizing: border-box;
    background-color: $brand_color_main;
    color: $text_white;
    border: none;
    font-size: 13px;
    text-transform: uppercase;
    font-family: $main_font;
    font-weight: 400;
    border-radius: 6px;
    cursor: pointer;
    margin: 0 auto 15px;
    outline: none;
    display: block;
}

.app__profile__button-container {
    margin: 180px 0 0 10px;
}

.app__profile__button--top-right,
.app__profile__button:last-of-type.app__profile__button--top-right {
    margin: 0 0 0 calc(100% - 260px);
    background-color: $brand_color_secondary;
}

.app__button.app__profile__button--top-right {
  margin-top: 15px;
  vertical-align: middle;
  padding-top: 4px;
  i {
    font-size: 17px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -2px;
    margin-right: 2px;
  }
}


.app__profile__button:last-of-type {
    background-color: $brand_color_accent;
}

.app__profile__button.active {
    background-color: $brand_color_accent;
    outline: none;
}

.skin2 .app__profile__button.active {
    background-color: $form_element_color_2;
}

.skin3 .app__profile__button.active {
    background-color: $form_element_color_3;
}

.app__profile__contacts {
  margin-top: 30px;
}

.app__profile__contacts .app__half-box {
  margin-top: 10px;
}

.app__profile__contacts .app__recipients__heading {
  color: $white;
}

.app__profile__contacts .app__recipients__list {
  border-top: 2px white solid;
}

.app__profile__update {
    height: 32px;
    width: 90px;
    box-sizing: border-box;
    background-color: transparent;
    color: $navigation_color;
    bottom: 2px;
    float: right;
    border: none;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
}

.app__profile__change-password {
    background-color: $brand_color_secondary;
    padding: 15px 0 25px;
    border: 2px solid white;
    margin-top: 55px;
    position: relative;
    z-index: 50;
}

.app__profile__main-panel {
    padding: 10px;
}

.app__profile__ok {
    background-color: $brand_color_accent;
    color: $text_white;
    border-radius: 5px;
    height: 45px;
    width: 40%;
    font-size: 15px;
    font-weight: 400;
    font-family: $main_font;
    text-transform: uppercase;
    cursor: pointer;
    margin: 15px 7px 0 7px;
    border: none;
    letter-spacing: 1px;
    display: inline-block;
}

.app__profile__ok--cancel {
    background-color: $brand_color_main;
}

.app__profile__tab {
    width: 137px;
    height: 32px;
    background-color: $form_element_color;
    color: $text_white;
    font-family: $main-font;
    font-size: 12px;
    border-radius: 12px 12px 0 0;
    box-sizing: border-box;
    text-align: center;
    padding-top: 9px;
}

.app__password__form {
    height: 116px;
    width: 100%;
    background-color: $form_element_color;
    text-align: center;
    padding-top: 38px;
    box-sizing: border-box;
    position: relative;
    transition: 0.5s;
}

.app__profile__password-input {
    border: 1px solid $table_border;
    background-color: $text_white;
    width: 238px;
    height: 41px;
    font-size: 16px;
    box-sizing: border-box;
    padding-left: 12px;
}

.app__profile__password-label {
    text-align: left;
}

.app__profile__left .admin__input-text {
    width: 80%;
    margin-left: 10%;
    padding: 12px;
}

.app__profile__password-label p {
    margin: 0 0 4px 10%;
    padding: 0;
    color: #ddd;
    /*$admin_form_label;*/
    text-align: left;
    font-size: 14px;
}

.app__profile__table-heading {
    font-weight: 400;
    font-size: 12px;
    color: $form_element_color;
    margin: 20px 0 5px;
}

.activity-loader {
    text-align: center;
}

.app__profile__activity-table {
    width: calc(100% + 12px);
    margin-left: -6px;
    box-sizing: border-box;
    /*border-collapse: collapse;*/
    table-layout: fixed;
}

.app__profile__activity-table th {
    height: 30px;
    font-size: 14px;
}

.app__profile__activity-table tr {
    border-bottom: 1px;
}

.app__profile__activity-table th {
    background-color: $tab-color;
    color: $text_white;
    border: 1px solid $tab_border;
    margin: 0 3px;
    height: 100%;
    box-sizing: border-box;

    &:first-of-type {
        margin: 0 0 0 3px;
    }

    &:last-of-type {
        margin: 0 3px 0 0;
    }

    //width: 100%;
}

.app__profile__activity-table td {
    font-size: 12px;
    line-height: 12px;
    color: $alt_text_color;

    height: 64px;
}

.app__profile__error-div {
    width: 100%;
    font-size: 14px;
    color: $admin_warning;
    font-weight: bold;
    font-family: $main_font;
    text-align: center;
    top: 11px;
}

.app__profile__error-div--top {
    width: auto;
    
}

.app__profile__logout {
    font-size: 12px;
    color: $form_element_color;
    position: absolute;
    top: 10px;
    right: 60px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.app__profile__photo-text {
  margin-top: 90px;
}

.app__profile__hide-show {
    position: absolute;
    width: 47px;
    height: 13px;
    background-image: url(/img/hide_sprite.png);
    top: 10px;
    right: 12px;
    cursor: pointer;
    z-index: 500;
    background-position: 0 -13px;
    display: none;
}

.app__password__form--hidden {
    height: 30px;

    .app__profile__password-label,
    .app__profile__ok,
    .app__profile__error-div {
        /*display: none;*/
        opacity: 0;
        visibility: hidden;
    }

    .app__profile__hide-show {
        background-position: 0 0;
    }
}

.skin2 {
    .app__profile__input-text__label-text {
        background-color: $secondary_form_color_2;
    }

    .app__profile__tab {
        background-color: $secondary_form_color_2;
    }

    .app__password__form {
        background-color: $secondary_form_color_2;
    }

    .app__profile__input-text__label-text {
        background-color: $form_element_color_2;
    }

    .app__profile__table-heading {
        color: $form_element_color_2;
    }

    .app__profile__big-icon {
        background-image: url(/img/user-icon_2.svg);
    }

    .app__profile__logout {
        color: $form_element_color_2;
    }
}

.skin3 {
    .app__profile__input-text__label-text {
        background-color: $secondary_form_color_3;
    }

    .app__profile__tab {
        background-color: $secondary_form_color_3;
    }

    .app__password__form {
        background-color: $secondary_form_color_3;
    }

    .app__profile__input-text__label-text {
        background-color: $form_element_color_3;
    }

    .app__profile__table-heading {
        color: $form_element_color_3;
    }

    .app__profile__big-icon {
        background-image: url(/img/user-icon_2.svg);
    }

    .app__profile__logout {
        color: $form_element_color_3;
    }
}



.app__video-capture {
    overflow: hidden;
    text-align: center;

    .error {
        font-size: 14px;
        color: #ed7622;
        font-weight: bold;
        font-family: Arial, sans-serif;
        text-align: center;
    }

    .app__video-buttons {
        margin-left: 335px;

        .app__profile__button.photocapture {
            float: left;
            margin-right: 10px;
            background: $brand_color_main;
        }

    }
}

.app__profile__user-photo {
    p {
        margin: 10px 30px 40px 30px;
        color: $text-white;
        font-weight: 300;
        line-height: 22px;
    }

    h3 {
        margin: 10px 0 0 30px;
        color: $text-white;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: .8px;
    }
}

.app__capture-frame {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    height: 400px;
    width: 400px;
    background-color: black;
    margin: 90px auto 0;
    border: 2px solid white;
}

.app__capture-frame--padded {
    padding: 50px 0;
}

.app__capture-target {
    position: absolute;
    width: 400px;
    height: 400px;
    opacity: 0.5;
}

.app__profile__contacts-container .app__button--back{
  margin-top: -15px;
}

.app__profile__activity-search {
    float: right;
    clear: right;
    width: 20%;
    padding: 14px 0px 20px 0px;
    text-align: right;

    input {
        color: #383737;
        font-size: 13px;
        border-radius: 0px;
        padding: 7px 13px;
        border: 1px solid #b7b7b7;
        width: 85px;
        margin: 0px 5px;
    }
}

.app__profile__activity-actions {
    padding: 14px 0px 20px 0px;
    display: inline-block;
    width: 80%;
    float: left;

    .react-datepicker-wrapper,
    .divider {
        float: left;
    }

    .divider {
        padding-top: 5px;
        color: #b7b7b7;
    }

    .react-datepicker__input-container input {
        color: rgb(56, 55, 55);
        font-size: 13px;
        border-radius: 0px;
        padding: 7px 13px;
        border: 1px solid #b7b7b7;
        width: 85px;
        margin: 0px 5px;
    }

    label {
        margin-left: 30px;
        font-size: 13px;
        margin-top: 8px;
        display: inline-block;
    }

    select {
        border: 1px solid #b7b7b7 !important;
        padding: 7px;
        margin-left: 6px;
        width: 100px;
    }

    .activity-action-submit {
        float: right;
        height: 32px;
        width: 70px;
        background-color: #2699fb;
        color: #ffffff;
        border: none;
        font-size: 13px;
        font-weight: 700;
        border-radius: 6px;
        cursor: pointer;
        margin-right: 35px;
    }
}

.app__profile__activity-table {

    h3 {
        width: 100%;
        clear: both;
        text-align: center;
        padding-top: 30px;
        color: #515050;
    }

    table {
        border-collapse: collapse !important;
    }

    .-sort-desc {
        box-shadow: none !important;

        &:before {
            content: "▼";
            float: right;
        }
    }

    .-sort-asc {
        box-shadow: none !important;

        &:before {
            content: "▲";
            float: right;
        }
    }

    th {
        width: 16% !important;
        padding: 5px 20px;
        border: 5px solid #fff;
    }

    tbody {
        tr {
            border-bottom: 1px solid $med_grey !important;
        }

        td {
            line-height: 18px;
            padding: 3px;
            white-space: pre-line;

            &:last-child {
                white-space: pre-wrap;
            }
        }
    }

    thead td {
        border: 5px solid #fff;
        white-space: pre-wrap;

        &:last-child {
            white-space: pre-wrap;
        }

        &:nth-child(3) {
            white-space: pre-wrap;
        }
    }
}

.app__profile__input-text__label-text[disabled] {
    background-color: #c4bfbf;
}

.app__profile__input-text[disabled] {
    color: #c4bfbf;
}

.app__profile__modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background: $brand_color_secondary;
  opacity: .5;
}