.admin {
  height: 100vh;
  background-image: url(/img/axon_bg.svg);
  background-size: cover;
  font-family: $main_font;
}

.admin a {
  color: #eb4f1c; //$admin_link;
  float: right;
  font-size: 12px;
}

.admin__column--left {
  width: 55%;
  float: left;
}

.admin__column--right {
  width: 45%;
  float: right;
}

.admin .app__container {
  border: 0;
  font-family: $main_font;
  min-height: calc(100vh - 285px);
  height: 100vh;
  max-width: 100vw;
  background-color: transparent;
}

.admin .app__header {
  background-image: none;
  margin-bottom: 46px;
}

.admin .app__login-panel {
  box-sizing: border-box;
  width: 350px;
  height: 480px;
  border-radius: 8px;
  /*box-shadow: 0 0 16px 8px $admin_glow;*/
  margin: calc(50vh - 190px) auto 0;
  padding: 30px 30px 0;
  font-size: 14px;
  margin-bottom: 48px;
  position: relative;
  font-family: $main_font;
}

.admin .app__login-panel-sso {
  box-sizing: border-box;
  width: 500px;
  height: 111px;
  border-radius: 8px;
  /*box-shadow: 0 0 16px 8px $admin_glow;*/
  margin: 0 auto;
  padding: 30px 30px 0;
  font-size: 14px;
  margin-bottom: 48px;
  position: relative;
}

.admin .app__login-panel-sso .admin__error-div--welcome {
  top: 50px;
  font-size: 16px;
}

.admin .app__login-panel p {
  text-align: center;
  color: white; /*$admin_text;*/
  font-size: 18px;
    font-family: $main_font;
margin-bottom:35px;
}

.admin .app__login-panel label > p {
  margin: 0 0 4px;
  padding: 0;
  color: #ddd; /*$admin_form_label;*/
  text-align: left;
  font-size: 14px;
}

.admin .app__login-panel p.no-link {
  margin-top: 74px;
}

.admin .app__login-panel p.welcome {
  font-size: 16px;
  opacity: 0.6;
}

.admin .app__login-panel .admin__input-text--alt + p {
  color: $admin_text;
}

.admin .app__login-panel p.forgotPassword {
  float: right;
  color: $admin_warning;
  margin-top: -10px;
}

.admin .app__login-panel p.forgotPassword a {
  color: $admin_warning;
  text-decoration: none;
  font-weight: 400;
}

.admin .app__login-panel__title {
  color: white; /*$admin-heading;*/
  font-size: 45px;
  line-height: 46px;
  margin: 0;
  text-align: center;
  margin-bottom: 30px;
  font-family: $heading_font;
  font-weight: 800;
  text-transform: uppercase;
  transform: scaleX(1.05);
  letter-spacing: 1px;
}

.admin .app__login-panel__title.app__login-panel__title--welcome {
  margin-bottom: 8px;
}

.admin .admin__form {
  margin-top: 5px;
}

.admin .admin__form.admin__form--forgot {
  margin-top: 55px;
}

.admin__input-text {
  //this is the closed-box version from Reset Password
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: none; /*1px solid $admin_form_element;*/
  border-radius: 4px;
  margin-bottom: 16px;
  font-family: $main_font;
  padding: 15px;
  font-size: 16px;
}

.admin label:last-of-type .admin__input-text {
  margin-bottom: 5px;
}

.admin .admin__input-text.admin__input-text--alt {
  //this is the open line version from Forgot Password
  border: 0;
  border-bottom: 1px solid $admin_form_element2;
  margin-bottom: 19px;
  padding-left: 1px;
  height: 25px;
  font-weight: bold;
}

.admin .admin__input-text.email-input::before {
  content: "mail";
  width: 18px;
  height: 15px;
}

.admin__button {
  background-color: $brand_color_accent;
  color: $text_white;
  border-radius: 8px;
  height: 45px;
  width: 192px;
  font-size: 17px;
  font-weight: 700;
  font-family: $heading_font;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: calc(50% - 96px);
  margin-top: 30px;
  border: none;
    /*transform: scaleX(1.05);*/
  letter-spacing: 1px;
  padding-top: 6px;
}

.admin .admin__logo {
  height: 53px;
  width: 158px;
  
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: absolute;
  left: calc(50% - 79px);
  bottom: 32px;
}

.admin__error-div {
  width: 100%;
  font-size: 12px;
  color: $admin_warning;
  font-weight: bold;
  font-family: $main_font;
  text-align: center;
  position: absolute;
  left: 0;
}

.admin__error-div--resetpassword {
  top: 290px;
}

.admin__error-div--welcome {
  top: 125px;
}

.admin .signIn__logo {
  height: 15vh;
  margin: 39vh 0 0 12vw;
}
