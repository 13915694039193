.app__header {
  box-sizing: border-box;
  width: 100%;
}

.app__header__container {
  max-width: 1024px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.app__header__profile {
  position: absolute;
  top: 10px;
  right: 15px;
  text-align: center;
}

.app__icon.app__header__icon {
  height: 40px;
  width: 40px;
  margin-bottom: -2px;
  border: none;
  display: block;
  float: right;
}

.app__header__username {
  font-family: $main_font;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.14px;
  color: $brand_color_secondary;
  display: block;
  float: left;
  text-align: right;
  margin: 4px 8px 0 0;
}

.app__header__username a {
  font-weight: 400;
  color: $brand_color_main;
  text-decoration: underline;
}

.app__header__tabs {
  margin-right: 0;
}

.app__header__tab {
  box-sizing: border-box;
  font-family: $main_font;
  font-size: 12px;
  text-transform: uppercase;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 156px;
  height: 38px;
  float: left;
  margin-left: 3px;
  cursor: pointer;
  background-size: cover;
  margin-top: 20px;
  margin-right: 4px;
  background: $brand_color_secondary;
  color: #fff;
  text-align: center;
  padding-top: 13px;
  &.active {
    background: $brand_color_main;
  }  
}
