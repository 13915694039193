.script-grid {
  font-family: $main_font;
  padding: 0 15px;
  p {
    font-size: 15px;
    margin: 25px 0;
    color: $brand_color_secondary;
    line-height: 19px;
  }
}

.app__script-grid__container {
  height: calc(100% - 255px);
  overflow-y: auto;
}

.app__flex-table {
  margin: 0 25px;
}

.app__flex-table__row {
  display: flex;
  flex-direction: row;
  min-height: 90px;
  flex-flow: row wrap;
  margin-bottom: 6px;
}

.app__flex-table__cell {
  flex: 1;
  text-align: left;
  display: flex;
  align-items: top;
  justify-content: left;
  padding: 10px 12px;
  font-family: $main_font;
  font-size: 12px;
  min-height: 90px;
  color: $text-black;
  cursor: pointer;
  /*border: 1px solid #7988ab;*/
  background-color: $white;
  box-sizing: content-box;
  &:nth-child(even) {
    background-color: #f2fafd;
  }
  position: relative;
  margin: 0 3px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

.app__flex-table__cell--selected {
  border-color: $brand_color_accent;
}

.app__flex-table__cell--selected::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border: 3px solid $brand_color_accent;
}
.app__flex-table__text {
  display: block;
}

.app__flex-table__hiddenpanel {
  min-height: calc(100% + 2px);
  background-color: $brand_color_secondary;
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(200% + 10px);
  z-index: 16;
  box-sizing: border-box;
  border: 1px solid $table_border;
  padding: 15px 18px;
  opacity: 0;
  font-size: 13px;
  line-height: 15px;
  display: none;  
  transition: 0.5s;
  color: $text-white;
}

.app__flex-table__cell--selected .app__flex-table__hiddenpanel {
  background-color: $brand_color_secondary;
  color: $text_white;
}


.skin2 .app__flex-table__cell--selected .app__flex-table__hiddenpanel {
  background-color: $brand_color_secondary;
  color: $text_white;
}

.skin3 .app__flex-table__cell--selected .app__flex-table__hiddenpanel {
  background-color: $brand_color_secondary;
  color: $text_white;
}



.app__flex-table__row:last-of-type {
  .app__flex-table__hiddenpanel {
    bottom: -1px;
    top: auto;
  }
}

.app__flex-table__cell:last-of-type {
  .app__flex-table__hiddenpanel {
    left: auto;
    right: -1px;
  }
}

.app__showpanel {
  display: block;
  opacity: 1;
}

.app__flex-table__close-panel {
  height: 14px;
  width: 14px;
  text-align: center;
  position: absolute;
  top: 3px;
  right: 12px;
  background-color: transparent;
  border: none;
  display: block;
  font-size: 16px;
  color: $table_border;
  cursor: pointer;
}

.app__flex-table__close-panel img {
  height: 14px;
  width: 14px;
}

.app__flex-table__expandbutton img {
  height: 10px;
  width: 10px;
}

.app__flex-table__expandbutton {
  height: 19px;
  width: 19px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  border: 1px solid black;
  padding: 0;
  text-align: center;
  z-index: 10;
}

.app__flex-table__expandbutton.app__flex-table__expandbutton--hidden {
  display: none;
}

.app__flex-table__row--header {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  margin-bottom: 3px;
}

.app__flex-table__cell--header {
  flex: 1;
  align-items: center;
  justify-content: left;
  font-size: 15px;
  padding: 8px 12px;
  text-align: left;
  font-weight: 400;
  color: $white;
  background-color: $brand_color_secondary;
  margin: 3px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

.app__flex-table__cell--header small {
  font-size: 12px;
  display: block;
}

.skin2 {
  .app__flex-table .app__flex-table__cell--selected {
    background-color: $form_element_color_2;
  }
}

.skin3 {
  .app__flex-table .app__flex-table__cell--selected {
    background-color: $highlight_3;
  }
}

.gridContainer{
  display: block;

  .previewSubject {
    font-family: Arial, sans-serif;
    color: $form_element_color;
    font-weight: bold;
    font-size: 14px;
  }
}