.app__activity-header {
    font-family: $main-font;
    color: $white;
    padding: 10px;

    h4 {
        font-size: 12px;
        font-weight: 500;
        margin-top: 0px;
    }
}

.app__activity-actions {
    padding: 10px;
    background: $brand_color_main;
    font-size: 12px;
    font-weight: 300;

    .react-datepicker-wrapper, .divider {
        float: left;
        margin-right: 10px;
    }
}


.app__activity__header-table td {
    white-space: pre-line !important;
    word-wrap: break-word;
}

.app__activity-no-results {
    color: $brand_color_secondary;
    font-size: 24px;
    display: block;
    margin: auto 0px;
    text-align: center;
    margin-top: 50px;
}

.app__button.activity-action-submit {
    background: $brand_color_secondary;
    padding: 6px 15px;
    margin: 0 10px;
}

.app__activity__search-div {
    margin-left: 30px;
    display: inline-block;
}

.app__activity__header-table {
    color: $text-black;
    margin-top: 15px;
    border-spacing: 0;
    width: 100%;
}

.app__activity__header-table td {
    font-size: 11px;
    padding: 0 7px;
    line-height: 14px;
    // &:nth-child(-n+3) {
    // 	font-size: 12px;
    // }
    padding: 3px;
    border-bottom: 1px solid $brand_color_main;
    background: $white;
}

.app__activity__header-table th {
    font-size: 10px;
    padding: 0 5px;
    font-weight: 700;
    text-align: center;
    background: $brand_color_secondary;
    color: $white;

}

.app__activity__header-table th:nth-child(1) {
    width: 120px;
}

.app__activity__header-table td.divider {
    background-color: $table_dividers;
    color: $text_white;
    font-size: 15px;
    font-weight: 700;
}

.app__activity__header-table tr {
    background-color: $exlight_grey;
    border-bottom: 1px solid $brand_color_main;

    height: 29px;

    &:nth-child(2) {
        background-color: $text_white;
    }

    &:first-child {
        background-color: transparent;

        span {
            color: white;
        }
    }

    &.has-limit {
        background-color: $alert_red;
        color: $text_white;
    }

    &.has-optout {
        background-color: $med_grey2;
        color: $text_white;
    }

    &.has-optout-npi {
        background-color: $med_grey2;
        color: $text_white;
    }
}


/*--------------*/


.pagination {
    width: 100%;
    border-top: 4px solid $brand_color_main;
    font-size: 12px;
    margin-top: 16px;
    color: $brand_color_secondary;
    padding-top: 10px;

    button {
        background-color: $brand_color_main;
        padding: 5px;
        border: none;
        color: white;
        cursor: pointer;
        margin-right: 10px;
        border-radius: 4px;
    }

    select {
        font-size: 12px;
        padding: 5px;
        background-color: $brand_color_main;
        color: white;
        border: none;
        border-radius: 4px;
        margin-left: 10px;
    }

    input[type="number"] {
        font-size: 12px;
        border: 0;
        border-radius: 3px;
        max-width: 60px;
        padding: 6px;
    }
}