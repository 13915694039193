.app__footer {
  width: 100%;
  box-sizing: border-box;
  font-family: $main_font;
}

.app__footer a,
.admin .app__footer a {
  font-weight: 400;
}

.app__footer__container {
  width: 100%;
  margin: 0 auto;
}

.app__footer__nav {
  font-size: 14px;
  font-family: $admin_font;
  margin-bottom: 4px;
}

.app__footer__nav-list {
  list-style: none;
  padding-inline-start: 0;
  margin: 0;
}

.app__footer__nav-item {
  display: inline-block;
  margin-right: 26px;
}

.app__footer__nav-item a {
  text-decoration: underline;
}

.app__footer__smalltext {
  text-align: center;
  font-size: 9px;
  line-height: 13px;
  color: $yet_another_greyx;
  margin: 2px 0 8px;
}

.app__footer__logo {
  height: 72px;
  width: 150px;
  background-image: url(/img/repcast-studio@2x.png);
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin: 0 auto;
}
