.recorder__modal {
  .app__modal__container {
    width: 900px;
    height: 80%;
    max-height: 540px;
    padding-bottom: 25px;

  }

  .app__recorder__record-light {
    position: absolute;
    z-index: 7000;
    font-size: 50px;
    height: 55px;
    width: 55px;
    color: $red;
    animation: blinker 1s linear infinite;
    top: 23px;
    left: 18px;
  }


  button {
    cursor: pointer;

    &:disabled {
      background-color: gray;
    }
  }

  .record__saving_indicator {
    text-align: center;
    
    img {
      margin-top: 15px;
    }
  }

  video {
    height: 100%;
  }

  .gif_loader {
    position: relative;
    background-image: url(/img/loader.svg);
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    margin: 0px auto;
    margin-top: -290px;
  }

  .gif__preview {
    height: 100%;
    margin-top: -34px;
    background-color: black;
    text-align: center;
    img {
      height: 100%;
    }
  }


  &.video__recorder {
    .app__modal__back {
      z-index: 1;
      top: 0px; 
      left: auto;
      right: 0;
      width: 27%;
      button {
        width: 150px;
      }
    }
    video {
      height: 300px;
    }
    .app__modal__container {
      height: 42%;
      top: 34%;
      border-radius: 6px;
    }
    .video__wrapper {
      padding-top: 10px;
    }
  }

  .record__hints {
    position: fixed;
    z-index: 1000000;
    width: 100%;
    height: 100%;
    background-color: rgba(180, 180, 180, .5);
    top: 0px;
    left: 0px;

    h4 {
      font-family: $heading_font;
      font-size: 20px;
      text-transform: uppercase;
      margin: 10px 0 0;
      padding-bottom: 0px;
      line-height: 15px;
      color: $brand_color_secondary;
    }

    .record__hint {
      background-color: white;
      color: $text_black;
      width: 415px;
      padding: 10px 20px;
      font-size: 16px;
      text-align: left;
      position: absolute;
    }


    .hint1 {
      top: 60px;
      left: 310px;
    }

    .hint2 {
      top: 260px;
      left: 60px;
      &:after {
      content: " ";
      height: 110px;
      width: 90px;
      display: block;
      position: absolute;
      left: 80px;
      background-image: url(/img/text-bubble.svg);
      background-size: 100% 100%;
      background-color: transparent;
    }
    }

    .hint3 {
      top: 260px;
      right: 40px;
      &:after {
      content: " ";
      height: 100px;
      width: 190px;
      display: block;
      position: absolute;
      left: 40px;
      background-image: url(/img/text-bubble.svg);
      background-size: 100% 100%;
      background-color: transparent;
      transform: scaleX(-1.0);
    }
    }

    .hint4 {
      top: 65%;
      right: 42px;
      &:after {
      //content: " ";
      height: 130px;
      width: 150px;
      display: block;
      position: absolute;
      left: 180px;
      background-image: url(/img/text-bubble.svg);
      background-size: 100% 100%;
      background-color: transparent;
      transform: scaleX(-1.0);
    }
    }

    p {
      margin-top: 11px;
    }

    .app__button.record__hint__button {
      background-color: $brand_color_secondary;
      font-size: 17px;
      padding: 15px; 
      height: 65px;
      width: 300px;
      position: absolute;
      left: calc(50% - 150px); 
      bottom: 5%;
    }
  }

  .record_intro {
    position: absolute;
    width: 500px;
    background-color: white;
    top: 150px;
    left: calc(50% - 250px);
    padding: 20px 26px;
    font-size: 13px;
    text-align: left;
    opacity: 0.9;

    &:after {
      content: " ";
      height: 130px;
      width: 200px;
      display: block;
      position: absolute;
      left: 280px;
      background-image: url(/img/text-bubble.svg);
      background-size: 100% 100%;
      background-color: transparent;
    }

    h4 {
      text-transform: uppercase;
      font-size: 16px;
      border-bottom: 3px solid $brand_color_main;
      line-height: 34px;
      padding-top: 0;
      margin-top: 0px;
      font-family: $heading_font;
    }
  }

  .countdown {
    top: -390px;
    position: relative;
    background-color: transparent;
    width: 220px;
    color: white;
    font-size: 30px;
    margin: 0 auto;
    padding: 30px 0px;
  }

  .countdown__numbers {
    width: 220px;
    position: absolute;
    top: 56px;
    color: $white;
    font-size: 130px;
    font-family: $heading_font;
    margin: 0 auto;
  }

  .countdown__text {
    width: 220px;
    font-size: 18px;
    position: absolute;
    top: 200px;
    color: $white;
    font-family: $heading_font;
    letter-spacing: .5px;
  }

  &.video__recorder .countdown {
    top: -450px;
  }

  .action_record {
    margin-top: -55px;
    position: relative;
    width: 180px;
    left: 75%;
    background-color: $brand_color_secondary;
    padding: 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    button {
      width: 160px;
      font-family: $main_font;
      margin-left: 0px;
      background-color: $red;
      font-size: 16px;
      padding: 12px 20px 10px;
      border: none;
      border-radius: 5px;
      color: white;
      cursor: pointer;
      text-transform: uppercase;
      i{
        font-size: 13px;
        width: 19px;
        margin-right: 5px;
        top: -1px;
        position: relative;
        border: 1px solid white;
        padding: 2px;
        border-radius: 10px;
        text-align: 0 auto;
      }
    }

    button:disabled {
      background-color: rgb(245, 165, 165);
    }
  }

  .action_script_position {
    margin-top: -35px;
    position: relative;
    width: 120px;
    left: 510px;

    button {
      margin-left: 0px;
      background-color: blue;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      color: white;
      cursor: pointer;
    }
  }

  &.video__recorder .action_record {
    position: absolute;
    padding: 0;
    width: 27%;
    background-color: transparent;
    right: 0;
    left: auto;
    z-index: 100;
    bottom: 5px;

    button {
      margin-left: 0px;
      background-color: $red;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      color: white;
    }

    button:disabled {
      background-color: rgb(245, 165, 165);
    }
  }
  &.video__recorder .action_record.square {
    button {
      background-color: $brand_color_main;
    }
  }

  .action_buttons {
    background-color: $brand_color_secondary;
    top: -200px;
    position: relative;
    width: 340px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 50px;
    margin: 0 auto;
    button {
      text-align: center;
      width: 84px;
      height: 84px;
      border-radius: 45px;
      border: none;
      background-color: green;
      color: white;
      cursor: pointer;
      text-transform: uppercase;
    }
  }

  .gif__review-button {
    i {
      font-size: 36px;
      margin-bottom: 2px;
      position: relative;
      top: -3px;
    }
    &:nth-child(1) {
      background-color: $brand_color_main;
    }
    &:nth-child(2) {
      background-color: $brand_color_accent;
    }
    &:nth-child(3) {
      background-color: $red;
    }
  }

  .back_review_button {
    background-color: blue;
    color: white;
    padding: 5px 10px;
    position: fixed;
    margin-left: -120px;
    margin-top: 10px;
    border: none;
  }

  



   .record__complete--video {
      height: auto;
      margin-top: -35%;
    }

   .record__complete__bottom {
      padding: 10px 15px 15px 15px;
      margin-top: 12px;
      p {
        font-size: 18px;
      }
    }

    .record__complete--video {
      input[type="text"] {
        border-radius: 4px;
        border: 0;
        height: 40px;
        font-size: 16px;
        width: 100%;
        margin-bottom: 4px;
      }
      small {
        font-size: 12px;
      }
      span {
        line-height: 10px;
      }
      button {
        margin-top: 10px;
        align-items: flex-end;
        position: relative;
        float: right;
      }
      .record__complete__body {
        width: 250px;
      }
    }


  &.video__recorder .record__complete {
    margin-top: 0px;
    width: 500px;
  }

  .video__wrapper {
    padding-top: 23%;
  }



  .script__wrapper_top {
    width: calc(100% - 20px);
    background: #2f2f2f;
    color: white;
    padding: 80px 60px;
    height: 45%;
    position: fixed;
    top: 10px;
    left: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    line-height: 1.5;
    text-align: left;
    border-radius: 8px;
    border: 2px solid #222;
    -moz-box-shadow:    inset 0 0 20px #111;
   -webkit-box-shadow: inset 0 0 20px #111;
    box-shadow:         inset 0 0 20px #111;
  }

  .script__wrapper_bottom {
    text-align: left;
    width: calc(100% - 20px);
    background: #2f2f2f;
    color: white;
    padding: 100px 60px;
    height: 45%;
    position: fixed;
    bottom: 10px;
    left: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    line-height: 1.5;
    border-radius: 8px;
    border: 2px solid #222;
        -moz-box-shadow:    inset 0 0 10px #111;
   -webkit-box-shadow: inset 0 0 10px #111;
    box-shadow:         inset 0 0 10px #111;
  }

  .script__shadow-overlay {
    display: none;
  }

  .script_controls {
    text-transform: uppercase;
    font-size: 14px;
    position: absolute;
    top: 15px; 
    width: 210px; //157
    margin-left: 20px;
    color: $text_white;

    button {
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      background: $brand_color_main;
      box-shadow: none;
      border: none;
      color: white;
      padding: 5px 10px;
      font-size: 13px;
      max-width: 180px;
      height: 38px;
      border-radius: 4px;
      padding: 3px 10px;
      text-transform: uppercase;
    }

    h5 {
      margin: 0 0 8px;
      font-size: 18px;
      text-align: center;
    }

    .form {
      margin: 0 auto;
      padding: 100px 30px 0;
    }

    .input-range {
      margin-bottom: 25px;
    }

    .input-range {
      position: relative;
      width: 100%;
      background-color: $brand_color_secondary-dark;
      padding: 22px;
      border-radius: 25px;  
      margin-top: 6px;
      &:nth-of-type(2) {
        .input-range__slider {
          background: $brand_color_main;
        }
        }
    }

    .input-range__label {
      display: none !important;
    }

    .input-range__track--background {
      left: 0;
      margin-top: -1px;
      position: absolute;
      right: 0;
      top: 50%;
    }
    .input-range__track {
      background: $brand_color_main;  
      border-radius: 20px;  
      cursor: pointer;
      display: block;
      height: 2px;
      position: relative;
      -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
      transition: left 0.3s ease-out, width 0.3s ease-out;
      //border: 15px solid $brand_color_secondary;
    }

    .input-range__track--active {
      background: #3f51b5;
    }

    .input-range__slider-container {
      -webkit-transition: left 0.3s ease-out;
      transition: left 0.3s ease-out;
    }

    .input-range__slider {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: $brand_color_accent;
      border-radius: 100%;
      cursor: pointer;
      display: block;
      height: 32px;
      width: 32px;
      margin-left: -0.5rem;
      margin-top: -16px;
      outline: none;
      position: absolute;
      top: 50%;
      -webkit-transition: box-shadow 0.3s ease-out,
        -webkit-transform 0.3s ease-out;
      transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
      transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
      transition: transform 0.3s ease-out, box-shadow 0.3s ease-out,
        -webkit-transform 0.3s ease-out;

    }
  }
}

.record__complete, .record__complete--video {
    position: relative;
    background-color: $brand_color_main;
    width: 506px;
    height: 356px;
    padding: 0;
    text-align: left;
    margin: 0 auto;
    top: 90px;
    border: 8px solid white;
    color: $text_white;
    &.record__complete--gif {

    }



    .record__complete__header {
      background-color: $brand_color_secondary;
      color: $text_white;
      height: 74px;
      padding: 1px 18px 6px;
      font-family: $heading_font;
      text-transform: uppercase;
      font-size: 20px;
      line-height: 18px;
      letter-spacing: 1px;
      border-bottom: 8px white solid;
    }

    .record__complete__body {
      display: inline-block;
      padding: 28px 18px 18px;
      width: 220px;
      font-size: 17px;
    }

    .record__complete__button-row {
      display: flex;
      width: 100%;
      position: relative;
      top: 32px;
      justify-content: space-between;
      padding: 0 18px;
    }

    .animated_gif_preview {
      width: 222px;
      height: 150px;
      float: left;
      margin: 18px 0 0 18px;
      padding: 0 36px;
      background-color: $text_black;
      display: inline-block;
    }

    .record__video__title {
      margin-top: 20px;
      padding: 5px;
      width: 90%;
    }
  }

  #email-sent-modal .record__complete__body {
    width: 100%;
    p {
      color: $text_white;
      font-size: 18px;
      font-weight: 700;
    }
    button{
      width: 95%;
      background-color: $brand_color_secondary;
      &:first-of-type {
        margin-top: 25px;
      }
      &:last-of-type {
        background-color: $brand_color_accent;
      }
    }
    .record__complete__big-icon {
      width: 75%;
      margin-left: 18px;
      display: block;
    }
  }

  #email-sent-modal .app__modal__container {
    background-color: transparent;
  }

  .app__modal--video-preview#email-sent-modal .app__modal__container {
    background-color: $brand_color_secondary;
  }

  video.animated_gif_preview {
      padding: 0 !important;
      height: 165px !important;
      width: 222px;
  }

/*----keyframes----*/

  @keyframes blinker {
    50% {
      opacity: 0.6;
    }
  }
