.contact__modal .app__modal__container {
  height: 455px;
  width: 720px;
  padding: 20px;
  overflow: hidden;
  color: $text_white;
  background-color: $brand_color_main;
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    font-size: 24px;
    color: $white;
    box-shadow: none;
    border: none;
  }
}
.contact__warning__modal .app__modal__container {
  height: 250px;
  width: 515px;
  color: #707070;
}

.overwrite__modal {

  .app__modal__container {
    height: 291px;
    width: 515px;
    margin-top: 100px;
    background-color: white;
  }

  .app__modal__text {
    font-size: 19px;
    padding: 20px 0px;
  }

  button {
    margin-top: 20px;
  }

  .app__modal__text, .app__modal__heading {
    color: $brand_color_secondary;
  }
}

.contact__modal.title {
  background: transparent;
  z-index: 6001;
  pointer-events: none;

  .app__modal__container {
    margin-top: calc(50vh - 278px) !important;
    background: none;
    box-shadow: none;
    width: 759px;
    overflow: hidden;

    p {
      background-color: $brand_color_main;
      color: $text_white;
      text-transform: uppercase;
      float: left;
      font-size: 14px;
      font-weight: bold;
      margin: 0px;
      padding: 10px 30px;
      -webkit-border-top-left-radius: 10px;
      -webkit-border-top-right-radius: 10px;
      -moz-border-radius-topleft: 10px;
      -moz-border-radius-topright: 10px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }
}

.contact__modal .app__modal__container {
  margin-top: calc(50vh - 230px) !important;
  -webkit-border-radius: 10px;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius: 10px;
  -moz-border-radius-topleft: 0;
  border-radius: 10px;
  border-top-left-radius: 0;

}

.overwrite__modal .app__modal__container {
  margin-top: calc(50vh - 230px) !important;
  -webkit-border-radius: 10px;
  -webkit-border-top-left-radius: 10px;
  -moz-border-radius: 10px;
  -moz-border-radius-topleft: 10px;
  border-radius: 10px;
  border-top-left-radius: 10px;
}

.contact__warning__modal .app__modal__container h3 {
  color: $admin_warning_2;
  font-size: 15px;
}

.contact__modal button {
  outline: none;
  cursor: pointer;
  float: right;
}

.contact__modal button:disabled,
.skin2 .contact__modal button:disabled {
  background-color: gray;
}


.contact__modal h4 {
  font-size: 14px;
  padding: 10px 0px 10px 0px;
  margin: 0px;
  text-align: left;
}

.app__contact__info-wrapper {
  margin-top: 20px
}

.app__contact__input-text__label {
  float: left;
  margin-right: 15px;
  margin-bottom: 5px;
}

.app__contact__input-text__label.clearfix {
  clear: left;
}

.app__contact__input-text__label-text {
  font-size: 12px;
  box-sizing: border-box;
  color: $text_white;
  padding-top: 5px;
  padding-bottom: 10px;
  text-align: left;
}

.app__contact__input-text {
  height: 32px;
  width: 160px;
  box-sizing: border-box;
  border: none;
  border-radius: 3px;
  font-size: 15px;
  padding-left: 15px;
}

.app__contact__input-text.specialty {
  width: 275px;
}

.app__contact__input-text.npi {
  width: 110px;
}

.app__contact__input-text#address {
  width: 250px;
}

.app__contact__input-text#city {
  width: 210px;
}

.app__contact__input-text.state {
  width: 51px;
}

.app__contact__input-text.zip {
  width: 84px;
}


/******/
.app__contact__add-edit-wrapper {
  margin-top: 15px;
  clear: both;

  .error {
    float: left;
    position: absolute;
    font-size: 15px;
    color: #fff;
  }
}

.app__contact-add-edit-buttons {
  font-size: 12px;
  float: right;
  padding: 5px 0px;
  width: 100%;
  background-color: #efefef;
}

.checkbox {
  width: 20px;
  height: 20px;
}

.app__contact__email-forms {
  clear: both;
  background: $brand_color_secondary;
  border: none;
  border-radius: 5px;
  overflow: auto;
  padding: 0px 0px 15px 15px;
}

.app__contact__ma-emails {
  float: left;
  width: 320px;
}

.skin2 .contact__modal button.app__contact-add-edit,
.contact__modal button.app__contact-add-edit,
.contact__modal button.app__contact-save{
  padding: 0px 3px;
  color: $empty_color;
  background: none;
  margin: 5px;
  width: auto;
  margin-left: 0px;
  float: right;
  background-color: none;
  font-size: 12px;
}

.contact__modal button.app__contact-save {
  background-color: $brand_color_accent;
  color: white;
  padding: 10px 30px;
}

.contact__modal button.app__contact-add-edit.disabled {
  display: none;
}

.contact__modal button.app__contact-add-edit.active {
  color: $form_element_color;
}

.skin2 .contact__modal button.app__contact-add-edit.active {
  color: $form_element_color_2;
}

.skin3 .contact__modal button.app__contact-add-edit.active {
  color: $form_element_color_3;
}

.skin2 .contact__modal button.close,
.skin2 button.app__contact-save ,
.skin2 button.app__contact-add {
  background-color: $form_element_color_2;
  outline: none;
}

.skin3 .contact__modal button.close,
.skin3 button.app__contact-save ,
.skin3 button.app__contact-add {
  background-color: $form_element_color_3;
  outline: none;
}


.contact__warning__modal button {
  background: $empty_color;
  margin-left: 0px;
  padding: 0px;
  text-transform: uppercase;
  margin-top: 20px;
  border-radius: 18px;
  height: 38px;
}

.app__contact__button {
  height: 32px;
  width: 150px;
  box-sizing: border-box;
  background-color: $navigation_color;
  color: $text_white;
  bottom: 2px;
  border: none;
  font-size: 13px;
  font-weight: 700;
  border-radius: 6px;
  cursor: pointer;
  float: right;
  clear: right;
  margin-bottom: 20px
}

.app__contact__footer {
  overflow: hidden;
  font-size: 11px;
  float: left;
  width: 70%;
}

.app__contact__footer-info {
  text-align: left;
}

.app__contact__footer span {
  display: block;
  font-weight: bold;
  color: $form_element_color;
}

.skin2 .app__contact__footer span {
  color: $form_element_color_2;
}

.skin3 .app__contact__footer span {
  color: $form_element_color_3;
}

button.app__contact-add, button.app__contact-save {
  float: right;
  margin-top: 40px;
  padding: 10px 10px;
  background-color: $brand_color_accent;
  width: 190px;
}

.app__contact__add-edit-wrapper input[type="text"] {
  width: 250px;
  padding: 3px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 12px;
  padding-left: 15px;
  box-shadow: none;
  border: none;
  border: 2px solid white;
}

.app__contact__add-edit-wrapper input[type="text"]:disabled {
  background-color: #f7f7f7;
}

.app__contact__add-edit-wrapper .app__contact__input-text-email.primary {
    border: 2px solid $brand_color_accent;
}


.skin2 .app__contact__add-edit-wrapper .app__contact__input-text-email.primary {
  border: 2px solid $form_element_color_2;
}

