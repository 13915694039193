.app__modal {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 6000;
  background: $brand_color_modal;
  font-family: $main_font;
  top: 0;
  left: 0;
}

.app__modal__container {
  width: 540px;
  height: 100%;
  /*box-shadow: 0px 3px 6px #00000029;*/
  background-color: $brand_color_secondary;
  margin: 0 auto;
  margin-top: calc(35vh - 160px);

  position: relative;

  h3 {
    &:first-of-type {
      background-color: $brand_color_secondary;
      color: $white;
      width: 250px;
      position: relative;
      top: -30px;
      height: 30px;
      font-size: 16px;
      letter-spacing: 1px;
      padding: 13px 12px;
      text-align: left;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    i {
      width: 16px;
      margin-right: 7px;
      color: $red;
      background-color: $white;
      border-radius: 20px;
      padding: 1px;
      text-align: center;
      font-size: 14px;
      position: relative;
      top: -1px;
    }
  }
  h5 {
      color: $text_white;
      font-size: 16px;
      font-family: $main_font;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
      text-align: left;
    }
}

.app__modal--video-preview .app__modal__container {
  height: 421px;
  width: 660px;
  padding: 40px 10px 10px;
  background-color: $brand_color_secondary;
  h5 {
    margin: -20px 0 8px 5px;
    font-size: 18px;
  }
}
.app__modal--video-preview {
  video {
    background-color: black;
  }
}

.app__modal__container.modal__complete {
  background-color: transparent;
}

.app__modal__screen {
  height: 100%;
  margin: -32px 0 0;
  background-color: black;
  position: relative;
}

.app__modal__great-job {
  text-align: center;
  padding-top: 64px;
}

.app__modal__heading {
  font-size: 37px;
  color: $form_element_color;
  font-weight: 700;
}

.app__modal__text {
  font-size: 29px;
  color: $form_element_color;
  margin: 0;
  padding: 0;
}

.app__modal__back {
  margin-top: 20px;
  position: absolute;
  width: 150px;

  left: 0;
}

.app__modal .loading {
  width: 250px;
}

.app__modal__green-check {
  position: absolute;
  left: 360px;
  top: 56px;
}

.app__modal__icons {
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 55px;
}

.app__modal__icon-container {
  width: 50%;
  text-align: center;
}

.app__modal__icon {
  width: 70px;
  height: 48px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0 auto 9px;
  cursor: pointer;
}

.app__modal__icon-link {
  font-size: 20px;
  text-decoration: underline;
  color: $navigation_color;
  font-weight: 400;
  &:hover {
    color: $navigation_hover;
  }
  cursor: pointer;
}

.app__modal__close--video-preview {
  background-color: transparent;
  border: none;
  color: $brand_color_accent;
  font-size: 21px;
  position: absolute;
  top: 2px;
  right: 5px;
}
.app__modal__icon--sendagain {
  background-image: url(/img/envelope-open.svg);
}

.app__modal__icon--review {
  background-image: url(/img/analytics-monitor.svg);
}

.skin2 {
  .app__modal__heading,
  .app__modal__text {
    color: $form_element_color_2;
  }
}

.skin3 {
  .app__modal__heading,
  .app__modal__text {
    color: $form_element_color_3;
  }
}

